.hr {
  background-color: #dafeb7;
  height: 4px;
  border: 4px solid #dafeb7;
  opacity: 1;
}

.hr-black {
  color: #000103;
  height: 2px;
  border: 2px solid #000103;
  opacity: 1;
}

@media all and (max-width: 768px) {
  .projectSize {
    padding: 25px;
  }
}