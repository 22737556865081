.tri-three2 {
  width: 0; 
  height: 0; 
  border-top: 600px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 300px solid #DAFEB7;
  z-index: -1;
  position: absolute;
  transform: rotate(75deg);
  top: 100%;
  right: 50%;
}

.synonym {
  color: #dafeb7;
  text-decoration: none;
  transition-duration: 100ms;
  text-shadow: 1px 1px 3px #000;
  z-index: 1
}

.synonym:hover {
  transition-duration: 100ms;
  font-size:30px;
  transition: all 500ms;
  font-size-adjust: 20px;
  color: #84dd63;
  
}

@media all and (max-width: 560px) {
  .tri-three2 {
    border-top: 500px solid transparent;
    border-bottom: 900px solid transparent;
    border-left: 500px solid #DAFEB7;
    transform: rotate(180deg);
    text-align: right;
    top: 250%;
    right: 0%;
  }
}

@media all and (max-width: 1020px) {
  .tri-three2 {
    top: 90%;
  }
}