.view {
  height: 100vh;
}

.tri-one {
  width: 0; 
  height: 0; 
  border-left: 30px solid transparent;
  border-right: 350px solid transparent;
  border-bottom: 70px solid black;
}

.tri-two {
  width: 0; 
  height: 0; 
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 40px solid #69747C;
}

.tri-three {
  width: 0; 
  height: 0; 
  border-top: 600px solid transparent;
  border-bottom: 90px solid transparent;
  border-left: 300px solid #DAFEB7;
}

.tri-four {
  width: 0; 
  height: 0; 
  border-top: 200px solid transparent;
  border-bottom: 350px solid transparent; 
  border-right:120px solid #D0CFCF;
}

.absolute {
  position: absolute;
  top: 0;
}

.absolute-intro {
  position: absolute;
  text-align: right;
  
  top: 15%;
  right: 3%;
}

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite; 
          animation: slide 4s linear infinite;
}

.arrowSliding2 {
  position: absolute;
  -webkit-animation: slide2 4s linear infinite; 
          animation: slide2 4s linear infinite;
}

.arrowSliding4 {
  position: absolute;
  -webkit-animation: slide4 25s linear infinite; 
          animation: slide4 25s linear infinite;
}

@-webkit-keyframes slide {
  0% { opacity:0; transform: translateY(15vh); }	
  20% { opacity:1; transform: translateY(9vh); }	
  80% { opacity:1; transform: translateY(-9vh); }	
  100% { opacity:0; transform: translateY(-15vh); }	
}
@keyframes slide {
  0% { opacity:0; transform: translateY(15vh); }	
  20% { opacity:1; transform: translateY(9vh); }	
  80% { opacity:1; transform: translateX(-9vw); }	
  100% { opacity:0; transform: translateX(-15vw); }	
}

@-webkit-keyframes slide2 {
  0% { opacity:0; transform: translateY(15vh); }	
  20% { opacity:1; transform: translateY(9vh); }	
  80% { opacity:1; transform: translateY(-9vh); }	
  100% { opacity:0; transform: translateY(-15vh); }	
}
@keyframes slide2 {
  0% { opacity:0; transform: translateY(15vh); }	
  20% { opacity:1; transform: translateY(9vh); }	
  80% { opacity:1; transform: translateY(-9vh); }	
  100% { opacity:0; transform: translateY(-15vh); }	
}

@-webkit-keyframes slide4 {
  0% { opacity:0; transform: translateX(15vw); }	
  20% { opacity:1; transform: translateX(9vw); }	
  80% { opacity:1; transform: translateY(-9vh); }	
  100% { opacity:0; transform: translateY(-15vh); }	
}
@keyframes slide4 {
  0% { opacity:0; transform: translateX(10vw); }	
  20% { opacity:1; transform: translateX(50vw); }	
  80% { opacity:0; transform: translateY(-9vh); }	
  100% { opacity:1; transform: translateY(-15vh); }	
}

@media all and (max-width: 768px) {
  .absolute-intro {
    top: 35%;
    right: 5%;
  }

  .tri-one {
    border-right: 250px solid transparent;
  }
}