.links {
  color: black;
}

.links:hover {
  transition-duration: 100ms;
  color: #69747C;
}

.grayscale {
  filter: grayscale(100%);
  transition-duration: 750ms;
}

.grayscale:hover {
  filter: none;
  transition-duration: 750ms;
}

.task {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  perspective: 800px;
  transform-style: preserve-3d;
}

.abstract,
.details {
  width: 100%;
  padding: 10px 0px;
  position: relative
}

.details {
  max-height: 0;
  padding: 0;
  overflow: hidden;
  visibility: hidden;
  transform: rotateX(-180deg);
  transform-origin: top center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: 1s transform ease;
}
.details:before {
  content: '';
  display: block;
  position: absolute;
  
}
.task:hover .details {
  max-height: none;
  overflow: visible;
  visibility: visible;
  transform: rotateX(0deg);
}

.borderColor {
  /* background-color: #D0CFCF; */
  /* border-shadow: 1px 1px 3px #000; */
  border: 1px solid #D0CFCF;;
  opacity: 1;
}

.borderColor:hover {
  background-color: #dafeb763;
  /* border-shadow: 1px 1px 3px #000; */
  border: 1px solid #D0CFCF;
  opacity: 1
}